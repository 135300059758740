<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Purchase invoices') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem"/>
<!--            <Button class="p-button-raised p-button-warning p-ml-2"-->
<!--                    @click="testApi"-->
<!--                    label="Test SmartAccounts API"></Button>-->
          </template>
          <template #end>
            <div class="p-d-flex p-ai-center p-mr-2" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
              <!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
              <Checkbox :disabled="dataTableIsLoading" @change="getItems" id="purchase-invoices-show-only-unpaid-inputswitch" :binary="true" v-model="showOnlyUnpaid"/>
              <div>
                <label for="purchase-invoices-show-only-unpaid-inputswitch" >
                  <div class="pointer p-p-2">Show only unpaid</div>
                </label>
              </div>
            </div>
            <CustomDropdown id="supplier"
                            class="p-mr-2"
                            style="min-width: 180px; max-width: 200px;"
                            ref="selectedSupplierDropdown"
                            :searchData="supplierSearchData"
                            v-model="selectedSupplier"
                            :options="suppliersData"
                            :disabled="dataTableIsLoading"
                            filterPlaceholder="Find supplier"
                            optionLabel="company_name"
                            :filter="true"
                            :filterFields="['name', 'company_name', 'reg_number']"
                            :loading="suppliersDataIsLoading"
                            @change="changeSupplier"
                            @filter="searchSuppliers"
                            :showClear="true">
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
                </div>
                <span v-else>Select supplier</span>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">
                  <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
                </div>
              </template>
            </CustomDropdown>
            <DatesRangeCalendar :disabled="dataTableIsLoading" :isEmptyByDefault="true" @change-dates-range="changeDatesRange" v-show="true" class="p-mr-2"/>
            <SearchInput :disabled="dataTableIsLoading" @search-items="getItems" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>
<!--        <div v-show="selectedInvoicesData?.length" class="layout-footer selected-invoices-sidebar" :class="{'selected-invoices-sidebar&#45;&#45;active': showInvoicesSidebar}">-->
<!--        <div v-if="selectedInvoicesData.length" class="selected-invoices-sidebar" :class="{'selected-invoices-sidebar&#45;&#45;active': showInvoicesSidebar}">-->
<!--            <div>Список счетов:</div>-->
<!--            <b>Invoices list:</b>-->
<!--        </div>-->
        <Toolbar class="p-mb-4" v-if="showInvoicesSidebar && selectedInvoicesData?.length">
          <template #start>
            <div>
              <div class="p-mb-2">
                <a href="#" @click.prevent="toggleShowDetailedInfo">
                  <i style="font-size: 0.9em" class="p-mr-1 pi" :class="showDetailedInfo ? 'pi-minus' : 'pi-plus'"></i>
                  <span>Show detailed info</span>
                </a>
              </div>
              <!--          <hr style="margin: 8px 0"/>-->
              <div v-show="showDetailedInfo">
                <div v-for="(data, index) of selectedInvoicesData" :key="index" class="">
                  <b>{{ $t('Supplier') }}: </b>{{ data.company_name }}
                  <div>
                    <b>Bank info: </b><span>{{ data.bank_name }}, {{ data.bank_account }}</span>
                  </div>
                  <div>
                    <b>Invoices: </b>
                    <span v-for="(invoice, index) of data.selectedInvoices" :key="index">
<!--                  {{ invoice }}<span v-if="index !== data.selectedInvoices.length - 1">, </span><span v-else>. </span>-->
                      {{ invoice }}<span v-if="index !== data.selectedInvoices.length - 1">, </span>
                    </span>
                  </div>
                  <div>
                    <b>Sum: </b>{{ formatCurrency(data.totalSum) }}
                  </div>
                  <hr style="margin: 8px 0"/>
                  <!--              <hr class="p-mt-3 p-mb-3"/>-->
                  <!--              <hr class="p-m-2"/>-->
                </div>
              </div>
              <div class="p-mb-2">
                <div>Number of selected:  {{ selectedItems?.length }}</div>
                <div>Total sum:  {{ formatCurrency(totalSelectedInvoicesSum) }}</div>
              </div>
              <div>
                <Button class="p-button-raised p-button-secondary p-mt-2 p-mr-2" label="Unselect all" @click="unselectAllItems(true)"/>
                <Button class="p-button-raised p-mt-2" label="Change status to Paid" @click="changeStatusOnClick"/>
              </div>
            </div>
          </template>
<!--          <template #end>-->
<!--          </template>-->
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :tableName="$t('Purchase invoices')"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'number', title: 'Number', sortable: true},
              {name: 'export_number', title: 'SA', sortable: false, isHidden: !settings.useSmartAccounts},
              {name: 'supplier', title: 'Supplier', sortable: false},
              {name: 'issued', title: 'Issued', sortable: true},
              {name: 'payment_due', title: 'Payment due', sortable: true},
              {name: 'overdue', title: '', sortable: false},
              {name: 'grand_total', title: 'Sum', sortable: true},
              {name: 'unpaid', title: 'Unpaid', sortable: true},
              {name: 'unpaid', title: 'Unpaid', sortable: true, isHidden: true},
              {name: 'state', title: 'State', sortable: true},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:multiselectHeader>
            <th v-show="unpaidInvoicesCount && $store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">
              <Checkbox @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>
            </th>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
              <td v-show="unpaidInvoicesCount && $store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                <Checkbox v-if="item.state === 1" :value="item.id" v-model="selectedItems"/>
              </td>
              <td>
                <span v-if="item.number">{{ item.number }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td v-if="settings.useSmartAccounts">
                <i v-if="item.export_number || item.export_id" class="ti-link" v-tooltip.top="item.export_number"></i>
                <i v-if="item.changed_after_export" class="pi pi-exclamation-triangle warning-color p-ml-1" v-tooltip.top="'Changed after export'"></i>
              </td>
              <td>
               <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.issued">{{ formatDay(item.issued) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.payment_due" :class="{'warning-color': (countOverdueDays(item.payment_due) <= 0) && item.state !== 5 }">{{ formatDay(item.payment_due) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.payment_due && item.state !== 5" :class="{'warning-color': countOverdueDays(item.payment_due) <= 0 }">{{ countOverdueDays(item.payment_due) }}</span>
              </td>
              <td>
                <span>{{ formatCurrency(item.grand_total) }}</span>
              </td>
              <td>
                <span>{{ formatCurrency(item.unpaid) }}</span>
              </td>
<!--              <td>-->
<!--                <span>{{ formatCurrency(item.grand_total - item.paid) }}</span>-->
<!--              </td>-->
              <td>
                <div  class="p-d-flex p-flex-wrap p-ai-center">
                  <div class="state-badge" :class="PurchaseInvoiceStates.find(state => state.num === item.state)?.color || ''">{{ PurchaseInvoiceStates.find(state => state.num === item.state)?.label[$i18n.locale] || '' }}</div>
                </div>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="p-d-flex">
                  <EditButton :disabled="disableEditButton && isItemUpdated(item.id) || !allDataLoaded || isItemSelected(item.id) || dataTableIsLoading"
                              @click="editItem(item)"/>
                  <DeleteButton @click="confirmItemDelete(item)"
                                :disabled="isItemSelected(item.id) || !allDataLoaded || dataTableIsLoading"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData v-show="!dataTableIsLoading" :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

<!--        @update-item="updatePurchaseInvoice"-->
        <PurchaseInvoiceModal :visible="itemModal"
                              :item="item"
                              :taxes="taxes"
                              :suppliers="suppliers"
                              :warehouseItems="warehouseItems"
                              @update-items="updateItems"
                              @close="closeItemModal">
        </PurchaseInvoiceModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

      <ConfirmModal :visible="changeStatusModal"
                    @confirm="changeStateToPaid"
                    @close="closeConfirmChangeStatusModal"/>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import PurchaseInvoiceModal from '@/pages/finance/components/PurchaseInvoiceModal'
import PurchaseInvoiceStates from '@/translations/states/PurchaseInvoiceStates'
import ConfirmModal from '@/components/ConfirmModal'
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'
import calculateMixins from '@/mixins/calculateMixins'
import httpMixins from '@/mixins/httpMixins'
import datatableMixins from '@/mixins/datatableMixins'
import settings from "@/settings";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";

export default {
  mixins: [ formatMixins, calculateMixins, httpMixins, datatableMixins ],
  components: { PurchaseInvoiceModal, ConfirmModal,DatesRangeCalendar },
	data() {
		return {
      showDetailedInfo: false,
      showOnlyUnpaid: false,
      // disableEditButton: false,
      changeStatusModal: false,
      showInvoicesSidebar: false,
      taxes: null,
      suppliers: null,
      warehouseItems: [],
      // isLoading: true,
      providerId: null,
      fieldsExpand: 'supplier,creator,positions,positions.tax,positions.creator,positions.order',
      apiLink: 'purchase-invoice/get-active-invoices',
      routePath: '/purchase-invoices',
      // searchData: '',
      sort: {
        column: 'issued',
        direction: '-',
      },
			// items: [],
      // itemModal: false,
      // itemDeleteModal: false,
      // confirmItemDeleteModal: false,
			// item: {},
      // updatedItemId: null,
      // updatedItemsIds: [],
      // itemToDelete: {},
			// submitted: false,
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
      // constants,
      // addedItems: [],
      // selectedItems: [],
      // selectAllCheckbox: [],
      selectedSuppliers: [],
      selectedInvoices: [],
      selectedInvoicesData: [],
      unpaidInvoicesCount: null,
      totalSelectedInvoicesSum: 0,
      PurchaseInvoiceStates,
		}
	},
  watch: {
    suppliers(value) {
      if (value?.length) {
        // this.suppliers = value
        this.suppliersData = [...value]
      } else {
        // this.suppliers = null
        this.suppliersData = null
      }

      // this.checkSupplierInArray()
    },
    // supplierSearchData() {
    //   console.log(this.supplierSearchData)
    // },
    // '$route'() {
    //   this.checkRouteQuery()
    //   if (this.$route.path === '/login' || this.$route.path !== '/purchase-invoices') {
    //     return false
    //   }
    //   this.getItems()
    // },
    selectedItems: {
      handler() {
        this.checkAllItemsAreSelected()
        this.toggleInvoicesSidebar()
        if (!this.selectedItems?.length) {
          this.showDetailedInfo = false
        }
      },
      deep: true
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        // if (this.itemModal) {
        //   this.closeItemModal()
        // }
        // if (this.confirmItemDeleteModal) {
        //   this.closeConfirmItemDeleteModal()
        // }
        if (this.changeStatusModal) {
          this.closeConfirmChangeStatusModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    this.getTaxes()
    this.getSuppliers().then(() => this.checkSupplierInArray())
    this.getWarehouseItems()
	},
  methods: {
    // async updatePurchaseInvoice(id) {
    //   this.showUpdatedItemPdfSpinner = true
    //   if (!id) return false
    //   this.updatedItemId = id
    //
    //   let foundItem = this.items?.find(item => item.id === id)
    //   if (foundItem) {
    //     const indexOfFoundItem = this.items.indexOf(foundItem)
    //     if (indexOfFoundItem !== -1) {
    //       const itemData = await this.getPurchaseInvoiceById(id)
    //       if (itemData) {
    //         this.items[indexOfFoundItem] = itemData
    //       }
    //     }
    //   }
    // },
    // async getPurchaseInvoiceById(id) {
    //   try {
    //     const { data, status } = await httpClient.post(`purchase-invoice/get-by-id?expand=${this.fieldsExpand}`, { id })
    //     if (status === 200 && data?.success && data.item) {
    //       return data.item
    //     } else {
    //       return null
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   } finally {
    //     this.$store.commit('changeUpdatedOrderId', null)
    //   }
    // },
    toggleShowDetailedInfo() {
      this.showDetailedInfo = !this.showDetailedInfo
    },
    // async testApi() {
    //   try {
    //     const { status, data } = await httpClient('site/get-smart-accounts-articles')
    //     // const { status, data } = await httpClient('order/test-api')
    //     if (status === 200 && data) {
    //       console.log(data)
    //     }
    //   } catch(err) {
    //     console.log(err)
    //   }
    // },
    toggleInvoicesSidebar() {
      this.showInvoicesSidebar = !!this.selectedItems?.length
      this.createSelectedInvoicesData()
    },
    changeStatusOnClick() {
      this.changeStatusModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmChangeStatusModal() {
      this.changeStatusModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    async changeStateToPaid() {
      if (!this.selectedItems?.length) return
      this.disableEditButton = true
      this.updatedItemId = null
      try {
        const { status } = await httpClient.post('purchase-invoice/multiple-change-state', { ids: this.selectedItems })
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
          // this.getItems(false)

          this.selectedItems.forEach(itemId => {
            const purchaseInvoice = this.items.find(i => i.id === itemId)
            if (purchaseInvoice) {
              purchaseInvoice.state = 5
              purchaseInvoice.unpaid = 0
            }
          })

          this.updatedItemsIds = [ ...this.selectedItems ]
          this.selectedItems = []

          this.closeConfirmChangeStatusModal()
          this.unselectAllItems()
          this.clearSelectedInvoicesData()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.disableEditButton = false
      }
    },
    clearSelectedInvoicesData() {
      this.selectedInvoicesData = []
      this.selectedSuppliers = []
      // this.selectedItems = []
      // this.selectedInvoices = []
    },
    createSelectedInvoicesData() {
      if (this.selectedItems?.length) {
        this.clearSelectedInvoicesData()
        this.selectedItems.forEach(itemId => {
          const invoice = this.selectedInvoices.find(i => i.id === itemId)
          if (!invoice) {
            const newInvoice = this.items.find(i => i.id === itemId)
            if (newInvoice) {
              this.selectedInvoices.push(newInvoice)
            }
          }
        })

        // let totalSelectedInvoicesSum = 0
        this.selectedInvoices.forEach(i => {
          // totalSelectedInvoicesSum += +i.grand_total
          const index = this.selectedItems.indexOf(i.id)
          if (index === - 1) {
            this.selectedInvoices = this.selectedInvoices.filter(invoice => invoice.id !== i.id)
          }
        })

        this.selectedInvoices.forEach(invoice => {
          if (invoice.supplier && invoice.supplier.company_name) {
            const supplier = this.selectedSuppliers.find(supplier => supplier.id === invoice.supplier_id)
            if (!supplier) {
              this.selectedSuppliers.push({ company_name: invoice.supplier.company_name, bank_name: invoice.supplier.bank_name, bank_account: invoice.supplier.bank_account, id: invoice.supplier_id })
            }
          }
        })

        this.totalSelectedInvoicesSum = this.selectedInvoices.reduce((sum, item) => sum + +item.grand_total, 0)

        this.selectedSuppliers?.forEach(supplier => {
          const selectedInvoices = this.selectedInvoices.filter(invoice => invoice.supplier_id === supplier.id)

          this.selectedInvoicesData.push({
            company_name: supplier.company_name,
            bank_name: supplier.bank_name,
            bank_account: supplier.bank_account,
            id: supplier.id,
            selectedInvoices: selectedInvoices?.map(inv => inv.number),
            totalSum: selectedInvoices?.reduce((sum, item) => sum + +item.grand_total, 0)
          })
        })
      }
    },
    selectAllItems() { // Don't delete
      if (!this.items?.length) return false
      this.items.filter(item => item.state === 1 && !this.isItemSelected(item.id)).forEach(item => this.selectedItems.push(item.id))
    },
    checkAllItemsAreSelected() { //Don't delete
      if (!this.items?.length) return false
      let allItemsAreSelected = true
      this.items.filter(item => item.state === 1).forEach(item => {
        if (!this.isItemSelected(item.id)) {
          allItemsAreSelected = false
        }
      })

      if (allItemsAreSelected && this.selectedItems?.length) {
        this.selectAllCheckbox = [0]
      } else {
        this.selectAllCheckbox = []
      }
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            supplierId: this.selectedSupplierId,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
            show_only_unpaid: +this.showOnlyUnpaid,
          },
          data: {
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })

        if (status === 200) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data
          this.unpaidInvoicesCount = data?.filter(item => item.state === 1)?.length

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }

      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
        this.checkAllItemsAreSelected()
      }
      // this.isLoading = false
      // this.disableEditButton = false

    },
    closeItemModal() {
      this.itemModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.item = {}
      this.submitted = false
    },
    // addNewItem() {
		// 	this.item = {}
		// 	this.submitted = false
		// 	this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
		editItem(item) {
			this.item = item

      if (item.order_consumable) {
        this.item.use_consumables = true
      } else {
        this.item.use_consumables = false
      }
      // if (item.order_positions) {
      //   this.item.positions = item.order_positions
      // }

      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
		},
    // confirmItemDelete(itemToDelete) {
    //   this.itemToDelete = itemToDelete
    //   this.confirmItemDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmItemDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmItemDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    deleteItem() {
      console.log('delete sales invoice function')
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.taxes && this.suppliers && this.warehouseItems)
    },
    // itemsArrayIsEmpty() {
    //     return !(this.items && this.items.length)
    // },
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">
.selected-invoices-sidebar {
  //position: fixed;
  //top: 35%;
  //bottom: 0;
  //right: 0;
  //left: 0;
  //padding: 10px 25px;
  font-weight: normal;
  //min-height: 80px;
  //font-size: 20px;
  //transform: translateY(100%);
  //z-index: 1000;
  //transition: all 0.15s ease-out;
}

//.selected-invoices-sidebar--active {
//  transform: translateY(0);
//}
</style>